import {fbAuth} from "../../services/tdFirebase";
import {signOut} from "firebase/auth";

function TdAuthSignOut(
  {
    onSuccess,
    ...props
  }
) {

  const handleSignOut = () => {
    signOut(fbAuth).then(() => {
      // Sign-out successful.
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }).catch((error) => {
      // An error happened.
    });
  }

  return <span {...props} onClick={handleSignOut}/>;

}

export default TdAuthSignOut;