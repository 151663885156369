import './TdFormField.css';
import {tdFormClassName} from "./TdForm";

function TdFormField(
  {
    label,
    description,
    error,
    hasError,
    className,
    type,
    ...inputProps
  }
) {

  const baseClassName = tdFormClassName + '-field';
  let wrapperClassName =  baseClassName;
  if (className) {
    wrapperClassName += ' ' + className;
  }
  if (hasError) {
    wrapperClassName += ' ' + baseClassName + '--has-errors';
  }

  return (
    <div className={wrapperClassName}>
      <label className={baseClassName + '__label'}>{label}</label>
      <textarea className={'td-input'}
             autoComplete="off"
             {...inputProps}
      />
      {
        (description) && <div className={baseClassName + '__description'}>{description}</div>
      }
      {
        (hasError) && <div className={baseClassName + '__errors'}>
          <div className={baseClassName + '-error'}>{error}</div>
        </div>
      }
    </div>
  )

}

export default TdFormField;
