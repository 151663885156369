import React from "react";
import TdLink from "../ui/TdLink";

function TdAuthModalFooterPw() {
  return (
    <li>
      <b>Forgot your password?</b><br/>
      <TdLink href="mailto:support@thediplomat.com">Click here</TdLink> to ask us to reset your password.
    </li>
  )
}

export default TdAuthModalFooterPw;