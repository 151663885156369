import classNames from "classnames";
import './tdModal.css';

function TdModalFooter(
  {
    className,
    ...props
  }
) {

  return <footer className={classNames('td-modal-footer',className)} {...props}/>

}

export default TdModalFooter;