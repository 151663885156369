import React from "react";
import TdLink from "../ui/TdLink";
import classNames from "classnames";

function DriAccountFooter({
  className
}) {
  return <ul className={classNames('list-disc pl-4 mt-8 prosa',className)}>
    <li className={'mb-2'}>
      <b>Need to change your password?</b><br/>
      <a href="mailto:support@thediplomat.com">Click here</a> to ask us to change your password.
    </li>
    <li className={'mb-2'}>
      <b>Need to change your email address?</b><br/>
      <a href="mailto:support@thediplomat.com">Click here</a> to ask us to change your email address.
    </li>
    <li className={'mb-2'}>
      <b>Do you wish to cancel your subscription?</b><br/>
      <a href="mailto:support@thediplomat.com">Click here</a> to ask us to cancel your subscription.
    </li>
    <li className={'mb-2'}>
      <b>Do you have any questions regarding your account or subscription?</b><br/>
      <a href="mailto:support@thediplomat.com">Click here </a> to send us an email.
    </li>
  </ul>
}

export default DriAccountFooter;