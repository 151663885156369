import './TdButton.css';
import classNames from "classnames";
import {createElement} from "react";

function TdButton(
  {
    className,
    look,
    loading,
    ...props
  }
) {
  const _className = 'td-button';

  return createElement(
    (props.href) ? 'a' : 'button',
    {
      ...props,
      className: classNames(
        _className,
        {
          [_className + '--' + look]: (look === 'accent' || look === 'ghost'),
          [_className + '--loading']: loading
        }
        , className)
    }
  );

}

export default TdButton;