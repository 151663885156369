import './TdSection.css';
import classNames from "classnames";

function TdSection(
  {
    step,
    title,
    className,
    children,
    ...props
  }
) {

  return (
    <section {...props} className={classNames('td-section',className)}>
      {(!!step || !!title) && <h2 className={'text-2xl font-serif font-bold'}>
        {(!!step) && <span>{step}</span>}
        {(!!title) && title}
      </h2>}
      {children}
    </section>
  )

}

export default TdSection;
