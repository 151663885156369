import {createPortal} from 'react-dom';
import TdWithAuth from "./components/tdAuth/TdWithAuth";
import TdWithPermission from "./components/tdAuth/TdWithPermission";
import DriAccount from "./components/driAccount/DriAccount";
import {DriDownloadPagePortal} from "./components/driDownload/DriDownloadPagePortal";
import {DriContactFormPortal} from "./components/DriContactForm/DriContactFormPortal";

import './components/ui/td-ui.css';

function DriAccountPortal(props) {
  let element = document.getElementById('dri-widget-account');
  return (!!element) ? createPortal(<DriAccount {...props}/>, element) : null;
}

function DriApp (props) {
  return <>
    <DriDownloadPagePortal {...props}/>

    {/*<p>Props</p>*/}
    {/*<pre>{JSON.stringify(props, undefined, 2)}</pre>*/}

    {/*<TdAuthModal*/}
    {/*  {...props}*/}
    {/*  footerSignedIn={<DriAccountFooter/>}*/}
    {/*/>*/}

    {/*<DriCheckoutPortal {...props}/>*/}
    <DriAccountPortal {...props}/>
    <DriContactFormPortal {...props}/>
  </>;
}

export default TdWithAuth(TdWithPermission(DriApp));
