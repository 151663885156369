import React, {useEffect, useState} from "react";
import TdInfo from "../ui/TdInfo/TdInfo";
import SvgUser from "../ui/Svg/SvgUser";
import TdLink from "../ui/TdLink";
import SvgLock from "../ui/Svg/SvgLock";
import TdButton from "../ui/TdButton/TdButton";
import SvgWait from "../ui/Svg/SvgWait";
import TdFormActions from "../tdForm/TdFormActions";
import DriSignInModal from "./DriSignInModal";


function Wait() {
    return <TdInfo icon={<SvgWait/>} className={'td-m--v2'}>
        <strong>Verifying...</strong>
        Please wait a moment.
    </TdInfo>
}

export function DriDownloadPage(
    {
        fileName,
        authSignedIn,
        hasPermissions,
        permissionsLoadingCount,
        ...props
    }
) {

    const pathSubscriptions = '/subscriptions';
    const pathAccount = '/account';

    const filePath = (fileName) ? '/documents/' + fileName + '.pdf' : '#';

    const [showLogin, setShowLogin] = useState(0);
    const toggleModal = (e) => {
        if (e){
            e.preventDefault()
        }
        setShowLogin(Date.now())
    }

    useEffect(() => {

    }, []);

    return (
        <>
            {(!!permissionsLoadingCount) ? (
                <Wait/>
            ) : (
                <>
                    {
                        (!!hasPermissions) ? <>
                            <TdFormActions>
                                <TdButton href={filePath} download={true}>Download PDF</TdButton>
                            </TdFormActions>
                        </> : <>
                            <TdInfo icon={<SvgLock/>} type={'alert'}>
                                <b>Subscribers Only</b><br/>
                                To read this Research Note you need to be a <TdLink className={'td-nowrap'} href={pathSubscriptions}>Diplomat
                                Risk Intelligence</TdLink> subscriber.
                            </TdInfo>
                            <TdFormActions>
                                <TdButton
                                    href={pathSubscriptions}
                                >Subscribe</TdButton>
                                {(!authSignedIn) ? <TdButton
                                    look={'ghost'}
                                    href={pathAccount}
                                    onClick={(e) => toggleModal(e)}
                                >Login</TdButton> : null}
                            </TdFormActions>
                            <ul className="list-disc pl-4 mt-8 prosa">
                                {(!authSignedIn) ? <li className="mb-2">
                                    <b>Already have a subscription?</b><br/>
                                    <a onClick={(e) => toggleModal(e)} href={pathAccount}>Click here</a> to login to your account.
                                </li> : null}
                                <li className="mb-2">
                                    <b>Ready to subscribe?</b><br/>
                                    <a href={pathSubscriptions}>Click here</a> to become an
                                    Diplomat Risk Intelligence subscriber.
                                </li>
                                <li className="mb-2">
                                    <b>Would you like to learn more?</b><br/>
                                    <a href="mailto:support@thediplomat.com">Click
                                        here</a> to get in touch.
                                </li>
                            </ul>
                        </>
                    }
                </>
            )}
            <DriSignInModal
                toggle={showLogin}
                onSuccess={()=>toggleModal()}
                onCancel={()=>toggleModal()}
            />
        </>
    )
}