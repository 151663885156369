import {createPortal} from "react-dom";
import DriContactForm from "./DriContactForm";

export function DriContactFormPortal(props) {
    const element = document.querySelector('#dri-widget-contact');
    let context = '';
    if (
        element &&
        element.dataset &&
        element.dataset.context
    ) {
        context = element.dataset.context;
    }
    return (!!element) ? createPortal(<DriContactForm {...props} context={context}/>, element) : null;
}