import SvgWrapper from "./SvgWrapper";
import React from "react";

function SvgUser() {
  return <SvgWrapper size={32}>
    <path d="M16,8a5,5,0,1,0,5,5A5,5,0,0,0,16,8Z"/>
    <path d="M16,2A14,14,0,1,0,30,16,14.0158,14.0158,0,0,0,16,2Zm7.9925,22.9258A5.0016,5.0016,0,0,0,19,20H13a5.0016,5.0016,0,0,0-4.9925,4.9258,12,12,0,1,1,15.985,0Z"/>
  </SvgWrapper>
}

export default SvgUser;