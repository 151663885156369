import './TdFormActions.css';
import {tdFormClassName} from "./TdForm";
import classNames from "classnames";

function TdFormActions(
  {
    className,
    ...props
  }
) {

  return <div {...props} className={classNames(tdFormClassName + '-actions',className)}/>

}

export default TdFormActions;
