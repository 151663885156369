import './TdFormField.css';
import {tdFormClassName} from "./TdForm";

function TdFormRadios(
  {
    label,
    description,
    error,
    hasError,
    className,
    name,
      options,
    ...inputProps
  }
) {

  const baseClassName = tdFormClassName + '-field';
  let wrapperClassName =  baseClassName;
  if (className) {
    wrapperClassName += ' ' + className;
  }
  if (hasError) {
    wrapperClassName += ' ' + baseClassName + '--has-errors';
  }

  return (
    <div className={wrapperClassName}>
      <label className={baseClassName + '__label'}>{label}</label>

        <div style={{
            display:'flex',
            flexDirection: 'column',
        }}>
            {(!!options) ? (
                options.map((option,index) => (
                    <label
                        key={option.value+''+index}
                        className="inline-flex items-center"
                        style={{
                            display:'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: (index) ? '8px' : 0
                    }}
                    >
                        <input
                            className="form-radio"
                            type="radio"
                            name={name}
                            value={option.value}
                            defaultChecked={!index}
                        />
                        <span className="ml-2" style={{marginLeft:'8px'}}>{option.label}</span>
                    </label>
                ))
            ) : null }
        </div>


      {
        (description) && <div className={baseClassName + '__description'}>{description}</div>
      }
      {
        (hasError) && <div className={baseClassName + '__errors'}>
          <div className={baseClassName + '-error'}>{error}</div>
        </div>
      }
    </div>
  )

}

export default TdFormRadios;
