import React, {useState} from "react";
import TdForm from "../tdForm/TdForm";
import TdFormFields from "../tdForm/TdFormFields";
import TdFormField from "../tdForm/TdFormField";
import TdFormActions from "../tdForm/TdFormActions";
import TdButton from "../ui/TdButton/TdButton";
import TdFormTextarea from "../tdForm/TdFormTextarea";
import {httpsCallable} from "firebase/functions";
import {fbFunctions} from "../../services/tdFirebase";
import TdInfo from "../ui/TdInfo/TdInfo";
import SvgCheckCircle from "../ui/Svg/SvgCheckCircle";
import SvgErrorCircle from "../ui/Svg/SvgErrorCircle";
import TdFormRadios from "../tdForm/TdFormRadios";

function DriContactForm({context}) {

    const [isProcessing, setIsProcessing] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const [errors, setErrors] = useState({});

    const onInput = (event) => {
        const target = event.target;
        if (target && target.name) {
            if (errors[target.name] && target.value) {
                setErrors({
                    ...errors,
                    [target.name]: false
                })
            }
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setIsProcessing(true);

        const payload = {
            context: (context) ? context : '',
            name: '',
            email: '',
            company: '',
            phone: '',
            message: '',
            ... ((context === 'quote') ? {is_for:'', no_users:''} : {})
        };
        const valueKeys = Object.keys(payload);

        const _errors = {};

        if (event && event.target) {
            const target = event.target;
            valueKeys.forEach(key => {
                if (target[key] && target[key].value) {
                    payload[key] = target[key].value;
                }
            });
        }
        valueKeys.forEach(key => {
            if (key !== 'phone' && key !== 'company' && !payload[key]) {
                _errors[key] = true;
            }
        });

        const setFirebaseError = (fbError) => {
            _errors.error = true;
            setErrors(_errors);
            setIsProcessing(false);
        };

        if (!Object.keys(_errors).length) {
            try {
                const callFunction = httpsCallable(fbFunctions, 'dri-contactForm');
                callFunction(payload)
                    .then(result => {
                        setIsProcessing(false);
                        setIsSent(true);
                        event.target.reset();
                    })
                    .catch(error => {
                        setTimeout(setFirebaseError,1,error)
                    });
            } catch (error) {
                setTimeout(setFirebaseError,1,error)
            }
        } else {
            setIsProcessing(false);
        }
        setErrors(_errors);
    }

    const _pleaseEnter = 'Please enter ';

    return (
        <>
            {
                (isSent) ? (
                    <TdInfo
                        icon={<SvgCheckCircle/>}
                        id={'dri-form-success'}
                    >
                        <b>Success</b><br/>
                        Thank you for your message. We will get back to you as soon as possible.
                    </TdInfo>
                ) : (
                    <TdForm
                        onSubmit={onSubmit}
                    >
                        <TdFormFields>
                            <TdFormField
                                name={'name'}
                                label={'Your Name'}
                                required={false}
                                hasError={errors['name']}
                                error={_pleaseEnter + 'your full name.'}
                                onChange={onInput}
                            />
                            <TdFormField
                                type={'email'}
                                name={'email'}
                                label={'Your Email'}
                                required={false}
                                hasError={errors['email']}
                                error={_pleaseEnter + 'a valid email address.'}
                                onChange={onInput}
                            />
                            <TdFormField name={'company'} label={'Your Company'} required={false}/>
                            <TdFormField name={'phone'} label={'Your Phone'}/>
                            <TdFormTextarea
                                name={'message'}
                                label={'Your Message'}
                                required={false}
                                cols="40" rows="10"
                                hasError={errors['message']}
                                error={_pleaseEnter + 'your message.'}
                                onChange={onInput}
                            />
                            {(context === 'quote') ? (
                                <>
                                    <TdFormRadios
                                        name={'is_for'}
                                        label={'The subscription is for'}
                                        options={[
                                            {
                                                label:'My organization / multiple users',
                                                value:'My organization / multiple users'
                                            },
                                            {
                                                label:'Personal use / one user',
                                                value:'Personal use / one user'
                                            }
                                        ]}
                                    />
                                    <TdFormField
                                        type={'number'}
                                        name={'no_users'}
                                        label={'How many subscriptions would you like to purchase?'}
                                        description={'Estimated number of users at your organization who need frequent access to our content or services'}
                                        min={1}
                                        defaultValue={1}
                                    />
                                </>
                            ) : null}
                        </TdFormFields>
                        <TdFormActions>
                            <TdButton type={'submit'} loading={isProcessing} disabled={isProcessing}>{(isProcessing) ? 'Submitting...' : 'Submit'}</TdButton>
                        </TdFormActions>
                        {
                            (errors.error) ? (
                                <TdInfo
                                    icon={<SvgErrorCircle/>}
                                    type={'error'}
                                >
                                    <b>Form submission failed</b><br/>
                                    We were not able to send your message. Please try again or contact us at <a href={'mailto:support@thediplomat.com'} className={'td-link td-nowrap'}>support@thediplomat.com</a>
                                </TdInfo>
                            ) : null
                        }
                    </TdForm>
                )
            }
        </>

    )

}

export default DriContactForm;