import React, {Component} from 'react';
import {ref, onValue} from "firebase/database";
import {fbDatabase} from "../../services/tdFirebase";

const _ermissions = 'ermissions';
const _permissions = 'p'+_ermissions;

const _permissionsExpired = _permissions+'Expired';
const _permissionsLoading = _permissions+'Loading';
const _permissionsLoadingCount = _permissionsLoading+'Count';
const _permissionsKey = _permissions+'Key';
export const _hasPermission = 'hasP'+_ermissions;

const _purchased = 'purchased';
const _subscriptions = 'subscriptions';

const defaultState = {
  [_permissionsKey]: 0,
  [_permissionsLoading]: {},
  [_permissionsLoadingCount]: 0,
  [_hasPermission]: false,
  [_permissions]: {},
}

const TdWithPermission = (WrappedComponent) => {
  return class extends Component {

    constructor(props) {
      super(props);
      this.listeners = {};
      this.state = {
        ...defaultState
      };
    }

    componentDidMount() {
      if (this.props.authUid) {
        this.registerListeners()
      }
    }

    componentDidUpdate(prevProps) {
      const uid = this.props.authUid;
      if (prevProps.authUid !== uid) {
        if (uid) {
          if (prevProps.authUid) {
            this.unregisterListeners();
          }
          this.registerListeners();
        } else {
          this.unregisterListeners();
        }
      }
    }

    componentWillUnmount() {
      this.unregisterListeners(true);
    }

    calcLoadingPermissions(loadingKey, isLoading){
      let loadingPermissionCount = 0;
      const loadingPermissions = {
        ...this.state[_permissionsLoading],
        [loadingKey]: (isLoading) ? 1 : 0
      }
      Object.keys(loadingPermissions).forEach(key => {
        loadingPermissionCount += loadingPermissions[key];
      });
      return {
        [_permissionsLoading]: loadingPermissions,
        [_permissionsLoadingCount]: loadingPermissionCount,
      }
    }

    handleListener = (key, value) => {

      const state = this.state;
      const now = Date.now();
      let hasPermission = false;
      let newState = {
        ...this.calcLoadingPermissions(key, false)
      };

      let permissions = {...state[_permissions]}
      permissions[key] = value;

      let permissionsExpired = {}

      Object.keys(permissions).forEach(key => {
        if (
          permissions[key] &&
          (
            permissions[key] === _purchased ||
            permissions[key] > now
          )
        ) {
          hasPermission = true;
          delete permissionsExpired[key];
        } else {
          delete permissions[key];
          if (value) {
            permissionsExpired[key] = value;
          }
        }
      });

      newState[_hasPermission] = hasPermission;
      newState[_permissions] = permissions;
      newState[_permissionsExpired] = permissionsExpired;
      newState[_permissionsKey] = now;

      this.setState(newState)
    }

    registerListeners = () => {
      const {
        authUid,
        subscriptions,
        purchases
      } = this.props;

      const permissionsPath = 'accounts/' + authUid + '/' + _permissions;

      if (purchases) {

        purchases.forEach(purchase => {
          if (
            purchase &&
            typeof purchase === 'object' &&
            purchase.id &&
            purchase.type
          ) {

            this.setState({
              ...this.calcLoadingPermissions(purchase.id, true)
            })
            const _ref = ref(fbDatabase, permissionsPath + '/' + purchase.type + '/' + purchase.id + '/created');
            this.listeners[purchase.id] = onValue(
              _ref,
              (snapshot) => {
                this.handleListener(purchase.id, (!!snapshot.val()) ? _purchased : null)
              }, function (error) {
                console.error(error);
              });
          }
        })
      }

      if (subscriptions) {

        subscriptions.forEach(subscription => {

          this.setState({
            ...this.calcLoadingPermissions(subscription, true)
          })

          const _ref = ref(fbDatabase, permissionsPath + '/paid_subscription/' + subscription + '/expires');
          this.listeners[subscription] = onValue(
            _ref,
            (snapshot) => {
              this.handleListener(subscription, snapshot.val(), _subscriptions)
            }, function (error) {
              console.error(error);
            });
        })
      }

    }

    unregisterListeners = (isUnmount) => {
      Object.keys(this.listeners).forEach(key => {
        this.listeners[key]();
      })
      if (!isUnmount) {
        this.setState({
          ...defaultState
        });
      }
    }

    render() {
      return <WrappedComponent
        {...this.props}
        {...this.state}
      />;
    }

  }
}

export default TdWithPermission;
