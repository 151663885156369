export const _hasErrors = 'hasErrors';

export const _errorValueMissing = 'valueMissing';
export const _errorPatternMismatch = 'patternMismatch';
export const _errorTooShort = 'tooShort';

export function addFieldErrorState(name, code, prevState) {
  let fieldErrors = (prevState[name] && prevState[name].errors) ? {...prevState[name].errors} : {}
  fieldErrors[code] = true;
  return calculateNewErrorState(name, fieldErrors, prevState);
}
export function setFieldErrorState(name, code, errorMessage) {
  let errors = {};
  errors[code] = errorMessage || true;
  return calculateNewErrorState(name, errors, {});
}

export function calculateNewErrorState(name, errors, prevState) {

  let _latestError = '';
  Object.keys(errors).forEach(key=>{
    if(errors[key]){
      _latestError=key;
    }
  })
  let newState = {
    ...prevState,
    [name]: {
      valid: !_latestError,
      errors: errors,
      error: _latestError
    },
    [_hasErrors]: false
  }
  Object.keys(newState).forEach(key=>{
    if(key !== _hasErrors && !newState[key].valid){
      newState[_hasErrors] = true;
    }
  })
  return newState
}

export function getInputEventErrors(event) {

  let errors = {};

  if (event && event.target && event.target.validity) {
    if (!event.target.validity.valid) {
      [
        'badInput',
        'customError',
        _errorPatternMismatch,
        'rangeOverflow',
        'rangeUnderflow',
        'stepMismatch',
        'tooLong',
        _errorTooShort,
        'typeMismatch',
        _errorValueMissing
      ].forEach(key => {
        if (event.target.validity[key]) {
          errors[key] = true;
        }
      })
    }
  } else {
    console.error('missing event.target.validity')
  }
  return errors

}

/*
validateValue = (name, value, tooShort, typeMismatch) => {
  const state = this.state;
  let error = false;
  if (!value) {
    error = 'missing';
  } else if (
    typeMismatch
  ) {
    error = _errorInvalid
  } else if (
    tooShort ||
    name === _password &&
    value.length < 6
  ) {
    error = _errorPasswordShort;
  } else if (
    name === emailConfirm &&
    state[_email] !== value ||
    name === _passwordConfirm &&
    state[_password] !== value
  ) {
    error = 'mismatch';
  }

  return error;

}
*/
/*
validateForm = () => {

  const props = this.props;
  const state = this.state;
  const extraFields = props.extraFields;

  const uid = (props[_authUid]);

  // double check errors
  const errors = state[_errors];
  const newErrors = {...state[_errors]};

  const isSignUp = state[_authForm] === 'up';
  let fields = [];

  if (!uid) {
    fields = isSignUp ? fieldsSignUp : fieldsSignIn;
  }

  if (
    (
      props.isEdu
    ) &&
    state[_authForm] !== 'in' &&
    extraFields &&
    extraFields.length
  ) {
    fields = fields.concat(extraFields);
  }

  fields.map(field => {
    const name = field.name;
    const error = this.validateValue(name, this.state[name]);
    if (error) {
      newErrors[name] = error;
    } else if (errors[name]) {
      delete newErrors[name];
    }
  });

  return {
    [_errors]: newErrors,
    [_hasErrors]: countKeys(newErrors)
  };

}
*/
