import {createElement} from "react";
import classNames from "classnames";

function TdLink({className, ...props}) {

  return createElement(
    (props && props.href) ? 'a' : 'span',
    {
      ...props,
      className: classNames('td-link', ' td-nowrap', className)
    }
  );

}

export default TdLink;