import TdInfo from "../ui/TdInfo/TdInfo";

function TdFormErrors(
  {
    message,
    items
  }
) {
  return <TdInfo
      type={'error'}
      icon={
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
        </svg>
      }
    >
        {(!!message) && <p>{message}</p>}
        {(!!items && items.length) && (
          <ul>
            {
              items.map(item => <li>{item}</li>)
            }
          </ul>
        )}
    </TdInfo>
}

export default TdFormErrors;
