import classNames from "classnames";
import './tdModal.css';

function TdModalMain(
  {
    className,
    ...props
  }
) {

  return <section className={classNames('td-modal-main',className)} {...props}/>

}

export default TdModalMain;