import React, {useEffect, useState} from "react";
import classNames from "classnames";

import SvgClose from "../ui/Svg/SvgClose";
import './tdModal.css';

function TdModal(
  {
    id,
    children,
    toggle = 0,
    triggerShow = 0,
    triggerHide = 0,
    onShow,
    onHide
  }
) {

  const [isActive, setIsActive] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!!toggle) {
      if (isActive) {
        setIsVisible(false);
      } else {
        setIsActive(true);
      }
    }
  }, [toggle]);

  useEffect(() => {
    if (!!triggerShow && !isVisible) {
      setIsActive(true);
    }
  }, [triggerShow]);

  useEffect(() => {
    if (!!triggerHide && isVisible) {
      setIsVisible(false);
    }
  }, [triggerHide]);

  useEffect(() => {
    if (isActive) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [isActive]);

  useEffect(() => {
    if(isVisible && typeof onShow === 'function') {
      onShow();
    }
    if(!isVisible && typeof onHide === 'function') {
      onHide();
    }
    if (!isVisible) {
      const timer = setTimeout(() => {
        setIsActive(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <div
      id={id}
      className={classNames(
        'td-modal',
        {
        'td-modal--active': isActive,
        'td-modal--show': isVisible
      }
      )}
    >
      {(isActive) && <>
        <div className={'td-modal-window'}>
          <div className={'td-modal-esc'} onClick={() => setIsVisible(false)} tabIndex={-1}><SvgClose/></div>
          <div className={'td-modal-content'}>
            {children}
          </div>
        </div>
        <div className={'td-modal-backdrop'} onClick={()=>setIsVisible(false)}/>
      </>}
    </div>
  )

}

export default TdModal;