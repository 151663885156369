import {createPortal} from "react-dom";
import {DriDownloadPage} from "./DriDownloadPage";

export function DriDownloadPagePortal(props) {
    // const element = document.getElementById('dri-account');
    const element = document.querySelector('#dri-widget-research-note');
    let fileName = '';
    if (
        element &&
        element.dataset &&
        element.dataset.date &&
        element.dataset.no &&
        element.dataset.cache)
    {
        fileName = 'dri-research-note-' + element.dataset.date + '-i' + element.dataset.no + '-' + element.dataset.cache;
    }
    return (!!element) ? createPortal(<DriDownloadPage {...props} fileName={fileName}/>, element) : null;
}