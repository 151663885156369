import React, {Component} from 'react';
import { fbAuth } from "../../services/tdFirebase";
import { onAuthStateChanged } from "firebase/auth";

function userState(user) {
  return {
    authKey: Date.now(),
    authSignedIn: !!(user && user.uid),
    authUid: (user && user.uid) ? user.uid : null,
    authEmail: (user && user.email) ? user.email : null
  }
}

const TdWithAuth = (WrappedComponent) => {
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        ...userState(fbAuth.currentUser),
        authKey: 0
      };
    }

    componentDidMount() {
      this.onAuth = onAuthStateChanged(fbAuth, (user) => {
        this.setState(userState(user));
      });
    }

    componentWillUnmount() {
      this.onAuth();
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state}/>;
    }

  }
}

export default TdWithAuth;
