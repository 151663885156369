import React from 'react';
import ReactDOM from 'react-dom';
import DriApp from "./DriApp";

ReactDOM.render(
  <React.StrictMode>
    <DriApp
      subscriptions={['dri-12m']}
      stripeConfig={
        {
          "key": process.env.REACT_APP_STRIPE_API_KEY,
          "successUrl": process.env.REACT_APP_CHECKOUT_SUCCESS_URL,
          "cancelUrl": process.env.REACT_APP_CHECKOUT_CANCEL_URL,
          "productId": "dri-12m",
          "priceId": process.env.REACT_APP_STRIPE_PRICE_ID
        }
      }
    />
  </React.StrictMode>,
  document.getElementById('dri-app-root')
);