import TdLink from "../ui/TdLink";
import React from "react";
import TdInfo from "../ui/TdInfo/TdInfo";
import SvgUser from "../ui/Svg/SvgUser";
import TdAuthForm from "../tdAuth/TdAuthForm";
import TdAuthModalFooterPw from "../tdAuth/TdAuthModalFooterPw";
import TdModalTitle from "../tdModal/TdModalTitle";
import TdButton from "../ui/TdButton/TdButton";
import TdModalFooter from "../tdModal/TdModalFooter";
import TdModalMain from "../tdModal/TdModalMain";
import TdModal from "../tdModal/TdModal";

export default function DriSignInModal(
    {
        toggle,
        onCancel,
        onSuccess
    }
) {

    return (
        <TdModal toggle={toggle}>
            <TdModalMain>
                <TdModalTitle>Sign in  <span>to your Diplomat account</span></TdModalTitle>
                <TdInfo icon={<SvgUser/>}>
                    <b>New to <i>DRI</i> or <i>The Diplomat</i>?</b><br/>
                    <TdLink className={'td-nowrap'} href={'/subscriptions'}>Create an account</TdLink> by purchasing a
                    subscription.
                </TdInfo>
                <TdAuthForm
                    buttonLabel={'Login'}
                    onSuccess={onSuccess}
                    secondaryActions={
                        <TdButton
                            look={'ghost'}
                            onClick={onCancel}
                        >Cancel</TdButton>
                    }
                />
            </TdModalMain>
            <TdModalFooter>
                <ul className={'td-ul'}>
                    <TdAuthModalFooterPw/>
                    <li>
                        <b>Do you have any questions regarding your account or subscription?</b><br/>
                        <TdLink href="mailto:support@thediplomat.com">Click here </TdLink> to send us an email.
                    </li>
                </ul>
            </TdModalFooter>
        </TdModal>

    )
}