import classNames from "classnames";
import './tdModal.css';

function TdModalTitle(
  {
    className,
    ...props
  }
) {

  return <div className={classNames('td-modal-title',className)} {...props}/>

}

export default TdModalTitle;