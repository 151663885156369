
function TdDate({timestamp}) {
  const date = new Date(timestamp);
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  return <time>{
    monthNames[date.getMonth()] +
    ' ' + date.getDate() +
    ', ' + date.getFullYear()
  }</time>
}
export default TdDate;