import './TdFormFields.css';
import {tdFormClassName} from "./TdForm";
import classNames from "classnames";

function TdFormFields(
  {
    className,
    ...props
  }
) {

  return <div {...props} className={classNames(tdFormClassName + '-fields',className)}/>

}

export default TdFormFields;
