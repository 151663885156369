import React, {useState} from 'react';
import {fbAuth} from "../../services/tdFirebase";
import {signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth";
import TdButton from "../ui/TdButton/TdButton";
import TdFormField from "../tdForm/TdFormField";

import {
  _Email,
  _email,
  _password,
  _Password,
  getErrorMessage,
  getErrorMessageField
} from "./tdAuthHelpers";

import TdFormActions from "../tdForm/TdFormActions";
import TdFormFields from "../tdForm/TdFormFields";
import TdForm from "../tdForm/TdForm";
import {
  _errorValueMissing,
  setFieldErrorState,
  calculateNewErrorState,
  getInputEventErrors,
  addFieldErrorState
} from "../tdForm/tdFormHelpers";
import TdFormErrors from "../tdForm/TdFormErrors";
import classNames from "classnames";

function TdAuthForm(
  {
    buttonLabel,
    isCreate,
    onSuccess,
    className,
    children,
    secondaryActions
  }) {

  const label = (buttonLabel) ? buttonLabel : (isCreate) ? 'Sign Up' : 'Login';
  const defaultValues = {
    [_email]: '',
    [_password]: ''
  }

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({...defaultValues});
  const [isSent, setIsSent] = useState(false);

  const onInput = (event) => {
    const name = event.target.name;
    setValues(prevState => {
      return {
        ...prevState,
        [name]: event.target.value
      }
    })
    setErrors(prevState => calculateNewErrorState(name, getInputEventErrors(event), prevState))
  }

  const setError = (name, error) => {
    setErrors(prevState => addFieldErrorState(name, error, prevState))
  }

  const callAuth = (e, p) => {
    if (isCreate) {
      return createUserWithEmailAndPassword(fbAuth, e, p)
    } else {
      return signInWithEmailAndPassword(fbAuth, e, p)
    }
  }

  const submit = () => {
    setIsSent(true);
    if (!values[_email]) {
      setError(_email, _errorValueMissing)
    } else if (!values[_password]) {
      setError(_password, _errorValueMissing)
    } else {
      setIsLoading(true);
      try {
        callAuth(
          values[_email],
          values[_password]
        ).then((user) => {
          // console.log('user', user)
          // setValues({...defaultValues});
          // setErrors({});
          // setIsLoading(false);
          if (typeof onSuccess === 'function') {
            onSuccess()
          }
        }).catch((error) => {
          const errorInfo = getErrorMessageField(error);
          // console.log('catch error',error);
          // console.log('catch error info',errorInfo)
          setErrors(setFieldErrorState(errorInfo.field, errorInfo.code, errorInfo.message));
          setIsLoading(false);
        });
      } catch (error) {
        console.error('catch error', error)
      }
    }
  }

  const showError = (name) => {
    return (isSent && errors[name] && errors[name].error) ? errors[name].error : '';
  }

  const fields = [
    {
      name: _email,
      label: _Email,
    },
    // {
    //   name: _emailConfirm,
    //   label: _ConfirmEmail,
    // },
    {
      name: _password,
      label: _Password,
      minLength: (isCreate) ? 6 : undefined,
      description: (isCreate) ? 'Choose a password with at least 6 characters.' : undefined,
      // },{
      //   name: _passwordConfirm,
      //   label: _ConfirmPassword,
    },
  ];

  return (
    <TdForm
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
      className={classNames(className)}
    >
      <TdFormFields>
        {fields.map(field => {
          const fieldName = field.name;
          return <TdFormField
            key={fieldName}
            {...field}
            type={fieldName}
            value={values[fieldName]}
            required={true}
            hasError={showError(fieldName)}
            error={getErrorMessage(showError(fieldName), fieldName)}
            onChange={onInput}
          />
        })}
      </TdFormFields>
      {(!!children) && children}
      {(errors.hasErrors && errors.auth && errors.auth.error) && <TdFormErrors message={errors.auth.error}/>}
      <TdFormActions>
        <TdButton
          type={'submit'}
          disabled={isLoading}
          loading={isLoading}
        >{(isLoading) ? 'Processing...' : label}</TdButton>
        {(!!secondaryActions) && secondaryActions}
      </TdFormActions>
    </TdForm>
  );
}

export default TdAuthForm;