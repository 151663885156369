import './TdInfo.css';
import classNames from "classnames";

function TdInfo(
  {
    type,
    icon,
    alignIcon,
    className,
    children,
    ...props
  }
) {

  const baseClassName = 'td-info';

  return <div className={classNames(
    baseClassName,
    (type) ? baseClassName + '--' + type :'',
    (alignIcon) ? baseClassName + '--icon-' + alignIcon :'',
    className
  )} {...props}>
    {(!!icon) && <div className={baseClassName+'__icon'}>{icon}</div>}
    <div className={baseClassName+'__body'}>{children}</div>
  </div>

}

export default TdInfo;
