function SvgWrapper(
  {
    children,
    fill = "#0369a1",
    size = "24",
    ...props
  }
) {
  return <svg
    fill={fill}
    height={size}
    width={size}
    viewBox={[0,0,size,size].join(' ')}
    xmlns="http://www.w3.org/2000/svg"
    children={children}
  />
}

export default SvgWrapper;