import TdDate from "../ui/TdDate";
import TdLink from "../ui/TdLink";
import React from "react";
import DriAccountFooter from "./DriAccountFooter";
import TdInfo from "../ui/TdInfo/TdInfo";
import SvgWait from "../ui/Svg/SvgWait";
import SvgUser from "../ui/Svg/SvgUser";
import TdSection from "../ui/TdSection/TdSection";
import TdAuthSignOut from "../tdAuth/TdAuthSignOut";
import TdAuthForm from "../tdAuth/TdAuthForm";
import TdAuthModalFooterPw from "../tdAuth/TdAuthModalFooterPw";

const _Expired = 'Expired';
const _Subscription = 'Subscription';
const _Subscriptions = _Subscription + 's';
const _PlanName = 'DRI ' + _Subscription;


function DriAccountSection(
  {
    isActive,
    timestamp
  }
) {
  return (
    // <section className={'td-m--v2'}>
    //   <div className={'td-heading'}>{(isActive) ? 'Active' : _Expired} {_Subscriptions}</div>
    <ul className={'td-list td-m--v2'}>
      <li>
        <b>{_PlanName}</b><br/>
        <span>{(isActive) ? 'Valid until' : _Expired + ' on'} <TdDate timestamp={timestamp}/></span>
      </li>
    </ul>
    // </section>
  )
}

function Wait() {
  return <TdInfo icon={<SvgWait/>} className={'td-m--v2'}>
    <strong>Loading...</strong>
    Please wait a moment.
  </TdInfo>
}

function DriAccount(
  {
    authSignedIn,
    authEmail,
    subscriptions,
    permissions,
    permissionsExpired,
    permissionsLoadingCount
  }
) {

  const plan = (subscriptions && subscriptions[0]) ? subscriptions[0] : 0;
  const _hasSubscription = (permissions && permissions[plan]);
  const _hasExpiredSubscription = (permissionsExpired && permissionsExpired[plan]);
  const _hasNoSubscription = (!_hasSubscription && !_hasExpiredSubscription);

  return <div>
    {
      (!!authSignedIn) ? <>
        <TdSection title={'Profile'}>
          <TdInfo icon={<SvgUser/>}>
            <p>
              You are signed in as '<b>{authEmail}</b>'.<br/>
              Not you? <TdAuthSignOut className={'td-link td-nowrap'}>Sign out</TdAuthSignOut>.
            </p>
          </TdInfo>
        </TdSection>

        <TdSection title={'Purchases'}>
          {(!!permissionsLoadingCount) ? (
            <Wait/>
          ) : (
            <>
              {(_hasSubscription) && <DriAccountSection isActive={true} timestamp={permissions[plan]}/>}
              {(_hasExpiredSubscription) && <DriAccountSection isActive={false} timestamp={permissionsExpired[plan]}/>}
              {(_hasNoSubscription) && (
                <ul className={'td-list td-m--v2'}>
                  <li>
                    <b>No purchases found</b><br/>
                    <span><TdLink href={'/subscriptions'}>Click here</TdLink> to subscribe to DRI.</span>
                  </li>
                </ul>
              )}
            </>
          )}
        </TdSection>
        <TdSection title={'Support'}>
          <DriAccountFooter className={'td-m--v2'}/>
        </TdSection>
      </> : <>
        <TdSection title={'Sign-in to your Account'}>
          <TdInfo icon={<SvgUser/>}>
            <b>New to <i>DRI</i> or <i>The Diplomat</i>?</b><br/>
            <TdLink className={'td-nowrap'} href={'/subscriptions'}>Create an account</TdLink> by purchasing a
            subscription.
          </TdInfo>
          <TdAuthForm/>
          <ul className={'td-ul'}>
            <TdAuthModalFooterPw/>
            <li>
              <b>Do you have any questions regarding your account or subscription?</b><br/>
              <TdLink href="mailto:support@thediplomat.com">Click here </TdLink> to send us an email.
            </li>
          </ul>
        </TdSection>
      </>
    }
  </div>
}

export default DriAccount;