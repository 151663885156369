import './TdForm.css';

export const tdFormClassName = 'td-form'

function TdForm(
  {
    className,
    ...props
  }
) {

  let wrapperClassName = tdFormClassName;
  if (className) {
    wrapperClassName += ' ' + className;
  }
  return <form {...props} className={wrapperClassName}/>

}

export default TdForm;
